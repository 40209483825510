import * as React from "react"
import { makeStyles } from '@material-ui/core/styles';
import MainLayout from '../../../layouts/main-layout';
import { LandingPageImage, WebsiteHeading, CompanySignupForm } from '../../../components';
import BoxesImage from '../../../images/boxes.png';

const useStyles = makeStyles((theme) => ( {
    pageBackground: {
        background: '#F6F8FC',
        minHeight: '100%',
    },
    page: {
        padding: '2rem',
        width: '100%',
        maxWidth: '1200px',
        margin: 'auto',
        position: 'relative',
        color: '#485776',

        [theme.breakpoints.down(420)]: {
            padding: '2rem',
        }
    },
    pageSection: {
        marginBottom: '2rem',
    },
    pageImage: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
} ));

const ProjectPhoenixPage = () => {
    const classes = useStyles();

    const PageImage = (
        <img alt="Smart packaging" className={classes.pageImage} src={BoxesImage} />
    );

    return (
        <MainLayout>
        <main className={classes.pageBackground}>
            <title>Project Phoenix</title>
            <LandingPageImage image={PageImage} text={[{ type: 'basic', text: 'Rethinking Packaging' }]} />     

            <div className={classes.page}>
                <WebsiteHeading isPageHeader text={'Project Phoenix'}/>
                <div className={classes.pageDescription}>
                    <p>
                        Project Phoenix is our first innovation in drastically altering the way we package goods. Using a range
                        of technologies and collaboration with third parties we will provide a new way for brands to design and
                        use packaging, offering a more sustainable service to their customers.
                    </p>
                    <p>
                        To test our service brands won't need to make any packaging changes, allowing a quick and risk-free 
                        onboarding that still significantly reduces waste. Using our smart service layer we can wrap around your 
                        current infrastructure,  reducing costs, proving consumer interest and increasing
                        brand sustainability. This way brands, manufacturers and suppliers have no significant upfront cost or 
                        risk and can trial our service as a proof of concept.
                    </p>
                    <p>
                        Simple packaging alterations go a long way and we can provide smart ways to adjust your packaging 
                        without hindering on design freedom or significant financial risk. Brands will retain full control 
                        over their packaging and retain their independence, always. 
                    </p>
                    <p>
                        In exchange for registering with us, retailers will gain a range of insights, free advertisement, increased 
                        footfall and you'll be doing your bit to help preserve out planet. It's a no-brainer.
                    </p>
                    <p>
                        Project Phoenix is currently in research &amp; development, ahead of schedule. Brands and retailers 
                        may now sign up to be part of a private research group. This gives you full view 
                        into what we're doing, allows you to give direct feedback into our 
                        solutions, and gives you an advantage over your competitors with another unique selling point 
                        when Phoenix goes live. We can handle any business size, big or small.
                    </p>
                    <p>
                        Interested? Sign the form below to get involved now. 
                    </p>
                </div>
                <div>
                    <CompanySignupForm />
                </div>
            </div>
        </main>
        </MainLayout>
    );
}

export default ProjectPhoenixPage;
